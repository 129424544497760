import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import ImageGalleryWithLightbox from "../../components/imageGalleryWithLightbox"

const Kurzaufenthalt = ({data}) => {

  // Single Image Data
  const images = {
    image1: data.image1.childImageSharp.fluid,
  }

  const title = "Kurzaufenthalt"

  return (
    <Layout title={title} slogan="" headerImage={data.image1.childImageSharp.fluid}>
      <div className="grid grid-2">
        <div>
          <h4>Unser Altersheim</h4>
          <ul>
            <li>Möchten Sie das Leben im Altersheim Bannau kennenlernen oder bei uns Probewohnen?</li>
            <li>Fahren Ihre Angehörige in den Urlaub und Sie fühlen sich allein?</li>
            <li>Sind Sie im Akutspital und sollen nach Hause, obwohl Sie noch nicht so weit sind? Brauchen Sie noch kleinere Unterstützungen?</li>
            <li>Würden Sie sich gerne von dem Alltag (Kochen, Waschen, Putzen) erholen?</li>
            <li>Würden Sie gerne mehr unter den Menschen Ihres Alters sein?</li>
          </ul>
          <p>
            Dann melden Sie sich bei uns für einen Kurzaufenthalt an! Für Ihre Fragen steht Ihnen gerne die Heimleitung Frau Beatrice Nufer zur Verfügung.
            Rufen Sie uns an oder schreiben Sie per Mail, wir melden uns so bald als möglich.
          </p>

        </div>
        <div>
          <ImageGalleryWithLightbox images={Object.values(images)} />
        </div>
      </div>
    </Layout>
  ) 
}

export default Kurzaufenthalt

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-misc-eingang-2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`